

export async function fetchProfile(): Promise<any> {
  
  const url = `/api/profile`;
  return  $fetch(url, {
      method: 'POST'
    }).then((res) => {
      return res
    }).catch((e) => {
      throw e
    });
}