import { default as _404lZJpwxvtooMeta } from "/vercel/path0/pages/404.vue?macro=true";
import { default as axsWWCtOSuHHsMeta } from "/vercel/path0/pages/axs.vue?macro=true";
import { default as _91id_93LygPCVwULHMeta } from "/vercel/path0/pages/games/[id].vue?macro=true";
import { default as index5VJlG6PCQRMeta } from "/vercel/path0/pages/games/index.vue?macro=true";
import { default as movie80LX2BFuLDMeta } from "/vercel/path0/pages/genre/[no]/movie.vue?macro=true";
import { default as tvXGVLH1gh8sMeta } from "/vercel/path0/pages/genre/[no]/tv.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91ulid_93ueF9ORjuoqMeta } from "/vercel/path0/pages/orders/[ulid].vue?macro=true";
import { default as indexan9ZrZAr6jMeta } from "/vercel/path0/pages/orders/index.vue?macro=true";
import { default as _91id_93A9SGFOyQrEMeta } from "/vercel/path0/pages/person/[id].vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
export default [
  {
    name: _404lZJpwxvtooMeta?.name ?? "404",
    path: _404lZJpwxvtooMeta?.path ?? "/404",
    meta: _404lZJpwxvtooMeta || {},
    alias: _404lZJpwxvtooMeta?.alias || [],
    redirect: _404lZJpwxvtooMeta?.redirect,
    component: () => import("/vercel/path0/pages/404.vue").then(m => m.default || m)
  },
  {
    name: axsWWCtOSuHHsMeta?.name ?? "axs",
    path: axsWWCtOSuHHsMeta?.path ?? "/axs",
    meta: axsWWCtOSuHHsMeta || {},
    alias: axsWWCtOSuHHsMeta?.alias || [],
    redirect: axsWWCtOSuHHsMeta?.redirect,
    component: () => import("/vercel/path0/pages/axs.vue").then(m => m.default || m)
  },
  {
    name: _91id_93LygPCVwULHMeta?.name ?? "games-id",
    path: _91id_93LygPCVwULHMeta?.path ?? "/games/:id()",
    meta: _91id_93LygPCVwULHMeta || {},
    alias: _91id_93LygPCVwULHMeta?.alias || [],
    redirect: _91id_93LygPCVwULHMeta?.redirect,
    component: () => import("/vercel/path0/pages/games/[id].vue").then(m => m.default || m)
  },
  {
    name: index5VJlG6PCQRMeta?.name ?? "games",
    path: index5VJlG6PCQRMeta?.path ?? "/games",
    meta: index5VJlG6PCQRMeta || {},
    alias: index5VJlG6PCQRMeta?.alias || [],
    redirect: index5VJlG6PCQRMeta?.redirect,
    component: () => import("/vercel/path0/pages/games/index.vue").then(m => m.default || m)
  },
  {
    name: movie80LX2BFuLDMeta?.name ?? "genre-no-movie",
    path: movie80LX2BFuLDMeta?.path ?? "/genre/:no()/movie",
    meta: movie80LX2BFuLDMeta || {},
    alias: movie80LX2BFuLDMeta?.alias || [],
    redirect: movie80LX2BFuLDMeta?.redirect,
    component: () => import("/vercel/path0/pages/genre/[no]/movie.vue").then(m => m.default || m)
  },
  {
    name: tvXGVLH1gh8sMeta?.name ?? "genre-no-tv",
    path: tvXGVLH1gh8sMeta?.path ?? "/genre/:no()/tv",
    meta: tvXGVLH1gh8sMeta || {},
    alias: tvXGVLH1gh8sMeta?.alias || [],
    redirect: tvXGVLH1gh8sMeta?.redirect,
    component: () => import("/vercel/path0/pages/genre/[no]/tv.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91ulid_93ueF9ORjuoqMeta?.name ?? "orders-ulid",
    path: _91ulid_93ueF9ORjuoqMeta?.path ?? "/orders/:ulid()",
    meta: _91ulid_93ueF9ORjuoqMeta || {},
    alias: _91ulid_93ueF9ORjuoqMeta?.alias || [],
    redirect: _91ulid_93ueF9ORjuoqMeta?.redirect,
    component: () => import("/vercel/path0/pages/orders/[ulid].vue").then(m => m.default || m)
  },
  {
    name: indexan9ZrZAr6jMeta?.name ?? "orders",
    path: indexan9ZrZAr6jMeta?.path ?? "/orders",
    meta: indexan9ZrZAr6jMeta || {},
    alias: indexan9ZrZAr6jMeta?.alias || [],
    redirect: indexan9ZrZAr6jMeta?.redirect,
    component: () => import("/vercel/path0/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93A9SGFOyQrEMeta?.name ?? "person-id",
    path: _91id_93A9SGFOyQrEMeta?.path ?? "/person/:id()",
    meta: _91id_93A9SGFOyQrEMeta || {},
    alias: _91id_93A9SGFOyQrEMeta?.alias || [],
    redirect: _91id_93A9SGFOyQrEMeta?.redirect,
    component: () => import("/vercel/path0/pages/person/[id].vue").then(m => m.default || m)
  },
  {
    name: searchBIFC2dE21WMeta?.name ?? "search",
    path: searchBIFC2dE21WMeta?.path ?? "/search",
    meta: searchBIFC2dE21WMeta || {},
    alias: searchBIFC2dE21WMeta?.alias || [],
    redirect: searchBIFC2dE21WMeta?.redirect,
    component: () => import("/vercel/path0/pages/search.vue").then(m => m.default || m)
  }
]